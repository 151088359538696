


















































































































































































































































































































import Vue from "vue";
import { Account } from "@/interfaces/settings/account";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  GET_PASSWORD,
  GET_USER_DETAILS,
  UPDATE_USER_DETAILS
} from "@/store/modules/auth/constants";
import { decrypt_password } from "@/utils/crypto";
import { get_password_validator_regex } from "@/utils/global";
import {
  email,
  maxLength,
  minLength,
  not,
  required,
  requiredIf,
  sameAs
} from "vuelidate/lib/validators";
import store from "@/store";
import { ROOT_NOTIFICATION } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "Account",
  data(): Account {
    return {
      name_icon: require("@/assets/icons/bold/user-octagon.svg"),
      email_icon: require("@/assets/icons/bold/sms.svg"),
      password_icon: require("@/assets/icons/bold/lock.svg"),
      show_password: false,
      edit_mode: false,
      edit_details_form: false,
      show_new_password: false,
      password: "**********",
      update_details_form: {
        name: "",
        email: "",
        current_password: "",
        new_password: ""
      }
    };
  },
  validations() {
    return {
      update_details_form: {
        name: {
          required
        },
        email: {
          required,
          email: email
        },
        current_password: {
          required,
          min: minLength(8),
          max: maxLength(16),
          valid: (value: string) => get_password_validator_regex().test(value),
          change: function (value: string) {
            return (
              value === decrypt_password(store.getters[`auth/${GET_PASSWORD}`])
            );
          }
        },
        new_password: {
          required: requiredIf((value) => value.length > 1),
          min: minLength(8),
          max: maxLength(16),
          unique: not(sameAs("current_password")),
          valid: (value) =>
            value.length > 1 ? get_password_validator_regex().test(value) : true
        }
      }
    };
  },
  mounted() {
    this.update_details_form.name =
      this.get_user_details.first_name + " " + this.get_user_details.last_name;
    this.update_details_form.email = this.get_user_details.email;
    this.update_details_form.current_password = decrypt_password(
      this.get_user_details.password
    );
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS,
      auth_loading: AUTH_LOADING
    })
  },
  methods: {
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("auth", {
      update_user_details: UPDATE_USER_DETAILS
    }),
    same_current_password(value: string): boolean {
      return value === decrypt_password(this.get_user_details.password);
    },
    transform_edit_icon(icon: HTMLElement): HTMLElement {
      const color = "#1C53F4";
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    display_password() {
      this.show_password = !this.show_password;
      if (this.show_password)
        this.password = decrypt_password(this.get_user_details.password);
      else this.password = "**********";
    },
    async update_details() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const name = this.update_details_form.name;
        const split_name = name.split(" ");
        const first_name = split_name[0];
        let last_name: string | null = null;
        if (split_name.length > 1) last_name = split_name.slice(1).join(" ");
        const obj = {
          first_name,
          last_name,
          email: this.update_details_form.email,
          password: this.update_details_form.new_password
            ? this.update_details_form.new_password
            : this.update_details_form.current_password
        };
        const result = await this.update_user_details(obj);
        if (result) {
          this.root_notification("Profile Updated Successfully");
          this.update_details_form.current_password = this.update_details_form
            .new_password
            ? this.update_details_form.new_password
            : this.update_details_form.current_password;
          this.update_details_form.new_password = "";
        }
      }
    }
  }
});
